export default {
  type: "locations",
  name: null,
  is_municipal: false,
  excerpt: "",
  relationshipNames: ["organization", "manager", "contacts"],
  organization: {
    type: "organizations",
    id: null,
  },
  manager: {
    type: "users",
    id: null,
  },
  contacts: [],
};
