<template>
  <div class="container-fluid">
    <location-form
      :loading="loading"
      :locationData="location"
      :formErrors="formErrors"
      @locationSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseLocationModal="onCloseLocationModal"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultLocation from "../defaultLocation";
import LocationForm from "../partials/LocationForm.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    LocationForm,
  },

  mixins: [alertLeave, requestErrorMixin],

  data() {
    return {
      location: cloneDeep(defaultLocation),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    goBack() {
      this.$router.push({ name: "List Locations" });
    },

    viewLocation(location) {
      this.alertLeave = false;
      this.$router.push({ name: "View Location", params: { id: location.id } });
    },

    onCloseLocationModal() {
      this.$emit("onCloseLocationModal");
    },

    async handleSubmit(location) {
      this.loading = true;

      const locationData = cloneDeep(location);
      delete locationData.id;

      try {
        await this.$store.dispatch("locations/add", locationData);
        this.$notify({
          type: "success",
          message: this.$t("LOCATIONS.LOCATION_ADDED"),
        });
        const location = await this.$store.getters["locations/location"];
        this.$emit("onViewLocation", location, true);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error?.response?.status === 403) {
          await this.showRequestError(error);
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.formErrors = error.response.data.errors;
        }
      }
    },
  },
};
</script>
