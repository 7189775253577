var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"add-form-inner"},[_c('div',{staticClass:"form-wrapper title"},[_c('h2',[_vm._v(_vm._s(_vm.$t("COMMON.LOCATION_INFORMATION")))])]),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.location.organization.id,"filterable":true,"showAll":false,"allowNone":false},on:{"organizationChanged":(organizationId) => {
              _vm.location.organization.id = organizationId;
              _vm.location.manager.id = null;
              _vm.location.contacts = [];
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.MANAGER')}`,"placeholder":_vm.$t('COMMON.MANAGER')}},[_c('user-selector',{attrs:{"placeholder":_vm.$t('COMMON.MANAGER'),"user":_vm.location.manager?.id,"filterable":true,"showAll":false,"filterOrganization":_vm.location.organization.id},on:{"userChanged":(userId) => {
              _vm.location.manager.id = userId;
              _vm.onFormChanged();
            }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.manager}})],1),_c('div',{staticClass:"form-wrapper full"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CONTACTS))?_c('base-input',{attrs:{"label":`${_vm.$t('CONTACTS.CONTACTS')}`,"placeholder":_vm.$t('CONTACTS.CONTACTS')}},[_c('contacts-selector',{attrs:{"placeholder":_vm.$t('COMMON.CONTACTS'),"contacts":_vm.location.contacts,"filterable":true,"showAll":false,"multiple":true,"filterOrganization":_vm.location.organization.id},on:{"contactsChanged":(contacts) => {
              _vm.location.contacts = contacts;
              _vm.onFormChanged();
            }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.contacts}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"placeholder":_vm.$t('COMMON.NAME')},on:{"change":_vm.onFormChanged},model:{value:(_vm.location.name),callback:function ($$v) {_vm.$set(_vm.location, "name", $$v)},expression:"location.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('el-switch',{attrs:{"active-color":"#576654","inactive-color":"#E5E7EB","active-text":_vm.$t('LOCATIONS.IS_MUNICIPAL')},on:{"change":_vm.onFormChanged},model:{value:(_vm.location.is_municipal),callback:function ($$v) {_vm.$set(_vm.location, "is_municipal", $$v)},expression:"location.is_municipal"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.is_municipal}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EXCERPT')}`,"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.location.excerpt),callback:function ($$v) {_vm.$set(_vm.location, "excerpt", $$v)},expression:"location.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1)]),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn cancel",attrs:{"type":"button"},on:{"click":_vm.onCloseLocationModal}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CANCEL"))+" ")]),_c('base-button',{staticClass:"btn btn-sm kw-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.location.id ? _vm.$t("COMMON.EDIT") : _vm.$t("COMMON.ADD_ITEM"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }