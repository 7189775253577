<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $t("COMMON.GENERAL_INFORMATIONS") }}</h3>
    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ location.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("LOCATIONS.IS_MUNICIPAL") }}</dt>
          <dd>
            <span v-if="location.is_municipal">
              {{ $t("COMMON.YES") }}
            </span>
            <span v-else>
              {{ $t("COMMON.NO") }}
            </span>
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <dt>{{ $t("COMMON.MANAGER") }}</dt>
          <dd>
            <users :users="location?.manager?.id ? [location.manager] : []" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            <div v-html="location.excerpt"></div>
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <dt>{{ $t("CONTACTS.CONTACTS") }}</dt>
          <dd>
            <contacts :contacts="location.contacts" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="location.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="location.created_at">
            {{ $formatDate(location.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="location.updated_at">
            {{ $formatDate(location.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import Users from "@/components/Users.vue";
import Contacts from "@/components/Contacts.vue";

export default {
  name: "location-view-global",

  components: {
    Users,
    Contacts,
  },

  props: ["location"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    location(location) {},
  },
};
</script>
