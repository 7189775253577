<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="add-form-inner">
      <div class="form-wrapper title">
        <h2>{{ $t("COMMON.LOCATION_INFORMATION") }}</h2>
      </div>

      <div
        class="form-wrapper"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')} (*)`"
          :placeholder="$t('COMMON.ORGANIZATION')"
        >
          <organization-selector
            :organization="location.organization.id"
            :filterable="true"
            :showAll="false"
            :allowNone="false"
            @organizationChanged="
              (organizationId) => {
                location.organization.id = organizationId;
                location.manager.id = null;
                location.contacts = [];
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.organization" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('COMMON.MANAGER')}`"
          :placeholder="$t('COMMON.MANAGER')"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <user-selector
            :placeholder="$t('COMMON.MANAGER')"
            :user="location.manager?.id"
            :filterable="true"
            :showAll="false"
            :filterOrganization="location.organization.id"
            @userChanged="
              (userId) => {
                location.manager.id = userId;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.manager" />
      </div>

      <div class="form-wrapper full">
        <base-input
          :label="`${$t('CONTACTS.CONTACTS')}`"
          :placeholder="$t('CONTACTS.CONTACTS')"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CONTACTS)"
        >
          <contacts-selector
            :placeholder="$t('COMMON.CONTACTS')"
            :contacts="location.contacts"
            :filterable="true"
            :showAll="false"
            :multiple="true"
            :filterOrganization="location.organization.id"
            @contactsChanged="
              (contacts) => {
                location.contacts = contacts;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.contacts" />
      </div>

      <div class="form-wrapper full">
        <base-input
          :label="`${$t('COMMON.NAME')} (*)`"
          :placeholder="$t('COMMON.NAME')"
          v-model="location.name"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.name" />
      </div>

      <div class="form-wrapper full">
        <!--<base-checkbox
          @change="onFormChanged"
          v-model="location.is_municipal"
          class="mb-3"
        >
          <span class="form-control-label">
            {{ $t("LOCATIONS.IS_MUNICIPAL") }}
          </span>
        </base-checkbox>-->
        <el-switch
          v-model="location.is_municipal"
          @change="onFormChanged"
          active-color="#576654"
          inactive-color="#E5E7EB"
          :active-text="$t('LOCATIONS.IS_MUNICIPAL')"
        >
        </el-switch>
        <validation-error :errors="apiValidationErrors.is_municipal" />
      </div>

      <!-- Excerpt -->
      <div class="form-wrapper full">
        <base-input
          :label="`${$t('COMMON.EXCERPT')}`"
          :placeholder="$t('COMMON.EXCERPT')"
        >
          <html-editor v-model="location.excerpt" @change="onFormChanged()">
          </html-editor>
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />
      </div>
    </div>

    <div class="submit-wrapper">
      <base-button
        class="btn cancel"
        type="button"
        @click="onCloseLocationModal"
      >
        {{ $t("COMMON.CANCEL") }}
      </base-button>
      <base-button
        type="button"
        class="btn btn-sm kw-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{ location.id ? $t("COMMON.EDIT") : $t("COMMON.ADD_ITEM") }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option, Switch } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import UserSelector from "@/components/UserSelector.vue";
import ContactsSelector from "@/components/ContactsSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    OrganizationSelector,
    UserSelector,
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
    ContactsSelector,
  },

  mixins: [formMixin],

  props: ["locationData", "formErrors", "loading"],

  data() {
    return {
      location: { ...this.locationData },
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      const locationData = cloneDeep(this.location);
      const me = this.$store.getters["profile/me"];
      if (!!me.organization?.id) {
        locationData.organization.id = me.organization.id;
      }
      if (!locationData.organization.id) {
        delete locationData.organization;
      }
      if (!locationData.manager.id) {
        delete locationData.manager;
      }
      if (locationData.reseller) {
        if (!locationData.reseller.id) {
          delete locationData.reseller;
        }
      }

      this.$emit("locationSubmitted", locationData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    onCloseLocationModal() {
      this.$emit("onCloseLocationModal");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    locationData(locationData) {
      if (locationData) {
        this.location = { ...this.location, ...cloneDeep(locationData) };
      }
    },
  },
};
</script>
